import React from 'react';
import ReactDOM from 'react-dom/client';
import '../styles/main.css';
import store from './store';
import Root from './containers/Root';

const appContainerElement = document.getElementById('app');
if (appContainerElement) {
  const root = ReactDOM.createRoot(appContainerElement);

  root.render(
    <Root store={store} />,
  );

  if ((module as any).hot) {
    (module as any).hot.accept('./containers/Root', () => {
      const NextRoot = require('./containers/Root').default;
      root.render(
        <NextRoot store={store} />,
      );
    });
  }
}

